import {graphql, StaticQuery} from 'gatsby'
import React from 'react'
import PalLogoAnim from '../components/pl-logo-anim'

import ContentBlock from '../components/content-block'
import Footer from '../components/footer'
import SEO from '../components/seo'
import '../styles/app.scss'
import '../styles/pages/home.scss'
import ContentPage from '../templates/content-page'

const IndexPage = class extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            content: staticPagesYaml(id: {eq: "home"}) {
              summary
            }
          }
        `}
        render={data => (
          <ContentPage
            logoColor="black"
            headerBackgroundColor="eggshell"
            className={'home' /* + (showSplash ? ' page-lock' : '')*/}
            headerVisible={false}
            render={({parent}) => (
              <>
                <SEO
                  title="Parts & Labour: Home"
                  keywords={[
                    `Parts`,
                    `Labour`,
                    `agency`,
                    `multi-platform`,
                    `nyc`,
                    `los angeles`,
                    `Toronto`,
                  ]}
                  description="A multi-platform content company based in LA NYC and Toronto"
                />

                <div className="container splash-section__container">
                  <div className="splash-section">
                    <div className="home-splash">
                      <div className="pal-logo-animation">
                        <PalLogoAnim />
                      </div>
                      <div>
                        <ContentBlock
                          summary={data.content.summary}
                          titleDivider={true}
                          smallSpacing={true}
                          divider={true}
                          thickDivider={true}
                          smallerFont
                          noJustify
                        />
                      </div>
                      <Footer left="2023, Coming Soon" />
                    </div>
                  </div>
                </div>
              </>
            )}
          />
        )}
      />
    )
  }
}

export default IndexPage
