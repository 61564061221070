import React from 'react';
import '../styles/components/pal-logo-anim.scss';

const PalLogoAnim = props => {
    const [on, setOn] = React.useState();
    let interval;
    React.useEffect(() => {
        interval = setInterval(() => {
            setOn(!on);
        }, 3500)
        
        return () => {
            clearInterval(interval)
        }
    }, [on])

  return (
    <svg className={`pal-logo-anim`} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 1920 456" xmlSpace="preserve">
        <g>
            <path className={`letter amp ${on ? 'on' : 'off'}`} d="M305.4,220.7l-3.2-3.4c-3.9,1.6-9.6,4.2-19.5,5c-2.1,0.2-4.2,0.3-6.4,0.3c-1.6,0-6.2-0.2-10.8-1.3
		c-3.1-0.8-18.7-5-18.8-19.8c0-2,0.2-5.8,3.1-9.8c4.5-6.1,12.1-8.2,18.8-10.3c-1.2-1.6-4.3-5.1-4.3-10.8c0-1.7,0.3-3.1,0.5-3.7
		c0.6-2.3,2.4-6.6,7.7-9.4c3.6-1.8,9.3-3.3,18.6-3.3c1.4,0,5.7,0,9.8,0.8c12.5,2.6,15.5,9.8,15.5,15.7c0,4-1.5,7.8-4.4,10.5
		c-4.4,4-12,5.9-13,6.1l8.6,8.8c2.1-2.3,2.9-3.8,4.2-6.6l17.9,2.5c-0.6,1.4-1.3,2.8-2,4.2c-3.2,6.1-7.9,10-9.6,11.5l12.1,12.9
		L305.4,220.7L305.4,220.7z M278.3,191.8c-3.8,1.2-8.4,3.1-8.4,7.8c0,0.7,0.2,2.4,1.2,4c1.5,2.3,4.8,4.5,10.6,4.5
		c2.4,0,5.3-0.3,10.1-1.8L278.3,191.8z M292,165.1c-4.4,0-5.9,2.7-5.9,4.6c0,1.3,0.7,3.1,2.5,5.4c0.8,0.9,1.4,1.4,1.8,1.7
		c0.9-0.2,1.7-0.4,2.6-0.7c4.1-1.5,5.3-3.4,5.3-5.7C298.3,168.2,296.9,165.1,292,165.1z"/>
            <path className={`letter l ${on ? 'on' : 'off'}`} d="M348.4,220.7V8.3h76.4v160.6h108.6v51.7L348.4,220.7L348.4,220.7z"/>
            <path className={`letter r2 ${on ? 'on' : 'off'}`} d="M1733,449l-44-77.4h-40.4V449h-76.5V236.3h156.7c28.6,0,56,2.1,73.2,29.2c3.9,6.3,10.8,19,10.8,38.3
		c0,9.9-2.1,38.3-28.9,53c-5.1,3-9,4.5-19.9,7.2l53,85H1733z M1733.6,293.3c-5.1-7.5-13.6-8.7-21.7-8.7h-63.3v39.2h57.2
		c14.5,0,22-1.8,26.8-7.5c3.9-4.8,4.2-9.6,4.2-12C1737,302.3,1736.7,297.5,1733.6,293.3z"/>
            <path className={`letter u ${on ? 'on' : 'off'}`} d="M1528.5,428.8c-18.4,17.2-49.1,27.7-103,27.7c-5.1,0-25.6,0-44.3-2.4c-7.2-0.9-50.9-6-70.8-36.1
		c-11.8-17.8-11.2-38.3-11.2-45.2V236.3h75.9v120.8c0,15.4,1.2,28.9,13.6,40.1c13.2,12,31.6,12,36.8,12c4.2,0,23.2-0.3,35.8-11.1
		c5.4-4.8,8.1-10.5,9-12.6c4.5-9.6,4.8-19.3,4.8-29.5V236.3h75.9v111.5C1551.1,376.7,1552,406.8,1528.5,428.8z"/>
            <path className={`letter o ${on ? 'on' : 'off'}`} d="M1240.2,432.7c-11.2,7.5-22,12.6-34.6,16.3c-12.3,3.6-31,7.2-61.2,7.2c-45.2,0-72.6-9.9-89.8-20.2
		c-5.7-3.3-16.3-10.2-26.8-22.9c-22.6-27.4-23.5-55.1-23.5-68.7c0-53.6,28.6-80.4,46.1-92.2c18.1-12.4,44-23.2,91.6-23.8
		c9.6,0,19,0,28.6,0.9c56.3,4.5,82.5,26.8,97.3,47.9c6.9,10.2,19.3,32.2,19.3,64.5C1287.2,360.8,1283.3,403.5,1240.2,432.7z
		 M1189.6,291.2c-3-3-10.2-8.4-20.5-12c-5.4-1.8-11.2-3.3-22.6-3.3c-9.9,0-18.1,0.9-27.4,4.8c-25,10.5-36.5,36.1-36.5,62.1
		c0,3.9,0.3,14.8,4.2,25.9c12.7,38,47,40.7,59.9,40.7c3.6,0,17.2,0,28.6-5.1c23.8-10.5,33.4-37.7,33.4-61.8
		C1208.9,315.3,1197.4,298.1,1189.6,291.2z"/>
            <path className={`letter b ${on ? 'on' : 'off'}`} d="M987.5,423.4c-19.6,23.5-53.9,25.6-81,25.6H742.9V236.3h178.6c21.4,0,47.6,1.5,60.2,22.3c3,4.8,7.2,13.9,7.2,27.4
		c0,4.8-0.3,18.4-9,29.5c-4.8,6-14.5,14.2-31.6,18.4c13.2,2.1,25.3,4.2,35.8,13.2c12.7,10.5,15.7,23.5,15.7,39.2
		C999.8,393,999.5,408.6,987.5,423.4z M904.9,363.5h-85.8v37h80.1c0.6,0,6.6,0,10.5-0.9c9.6-2.1,14.5-9,14.5-18.7
		C924.2,363.8,910,363.5,904.9,363.5z M913.7,291.8c-3.9-6.6-11.2-7.5-18.1-7.5h-75.9v33.1h70.5c7.8,0,19,0.6,23.8-8.1
		c1.2-2.4,2.1-5.7,2.1-8.4C916.1,299.3,916.1,295.7,913.7,291.8z"/>
            <path className={`letter a2 ${on ? 'on' : 'off'}`} d="M654,449l-11.5-34.6h-81.3L549.8,449H466l91.9-212.7h86.8L736.8,449H654z M601.6,295.4l-24.4,72h49.4L601.6,295.4z"/>
            <path className={`letter l2 ${on ? 'on' : 'off'}`} d="M279.2,449V236.3h76.5v160.9h108.8V449H279.2z"/>
            <path className={`letter amp2 ${on ? 'on' : 'off'}`} d="M1664,220.7l-2.2-2.4c-2.7,1.1-6.7,2.9-13.6,3.5c-1.5,0.1-2.9,0.2-4.4,0.2c-1.1,0-4.3-0.1-7.6-0.9
		c-2.2-0.6-13-3.5-13.1-13.8c0-1.4,0.1-4,2.2-6.9c3.1-4.3,8.4-5.8,13.1-7.2c-0.8-1.1-3-3.5-3-7.6c0-1.2,0.2-2.1,0.3-2.6
		c0.4-1.6,1.7-4.6,5.4-6.6c2.5-1.2,6.5-2.3,13-2.3c1,0,3.9,0,6.9,0.6c8.7,1.8,10.8,6.9,10.8,10.9c0,2.8-1,5.5-3.1,7.3
		c-3.1,2.8-8.4,4.1-9.1,4.3l6,6.2c1.4-1.6,2-2.6,2.9-4.6l12.5,1.7c-0.4,1-0.9,1.9-1.4,2.9c-2.2,4.2-5.5,7-6.7,8l8.4,9H1664z
		 M1645.1,200.5c-2.6,0.8-5.9,2.1-5.9,5.5c0,0.5,0.1,1.7,0.8,2.8c1,1.6,3.3,3.1,7.4,3.1c1.7,0,3.7-0.2,7.1-1.2L1645.1,200.5z
		 M1654.6,181.9c-3.1,0-4.1,1.9-4.1,3.2c0,0.9,0.5,2.1,1.7,3.7c0.6,0.6,1,1,1.2,1.2c0.6-0.1,1.2-0.3,1.8-0.5c2.8-1,3.7-2.4,3.7-3.9
		C1659,184.1,1658,181.9,1654.6,181.9z"/>
            <path className={`letter s ${on ? 'on' : 'off'}`} d="M1619.5,193.9c-17.8,22.3-49.7,34.6-107.8,34.6c-50,0-78.3-11.8-94-29.5c-14.5-16-16-32.5-17.2-40.4l75-1.8
		c0.6,3.6,2.1,9.3,9.6,14.8c7.2,5.4,17.8,9.3,37.7,9.3c7.8,0,17.5-0.9,24.7-4.5c6.3-3.3,12.1-9.3,12.1-16.9
		c0-4.8-2.1-11.1-12.1-15.4c-5.1-2.1-17.8-4.5-32.5-7.2c-16.6-3.3-33.4-6-50-10.5c-18.1-5.1-34.6-11.4-45.5-28
		c-7.8-12.1-8.4-24.1-8.4-29.5c0-11.5,3.3-22,9.3-31.3c13.9-22.3,43.4-37,97.9-37c18.7,0,48.5,2.4,66.3,8.4
		c7.5,2.4,14.5,6,20.8,10.8c20.2,14.8,23.2,33.4,24.7,42.8l-74.4,4.2c-0.9-2.4-1.5-4.8-2.7-6.9c-4.8-8.1-15.7-13-34.9-13
		c-1.2,0-19.6-0.3-28.6,7.2c-4.2,3.3-4.8,8.1-4.8,10.2c0,2.4,0.6,6.3,4.8,9.6c10.5,8.4,48.2,13.6,86.2,22.3
		c24.4,5.7,35.8,12.1,44,20.8c5.4,5.4,14.2,16.6,14.2,36.4C1633.6,167.9,1628.5,182.7,1619.5,193.9z"/>
            <path className={`letter t ${on ? 'on' : 'off'}`} d="M1331.2,55.6v165.1h-76.5V55.6h-75.6V8h227.4v47.6C1406.5,55.6,1331.2,55.6,1331.2,55.6z"/>
            <path className={`letter r ${on ? 'on' : 'off'}`} d="M1096.5,220.7l-44-77.4h-40.4v77.4h-76.5V8h156.7c28.6,0,56,2.1,73.2,29.2c3.9,6.3,10.8,19,10.8,38.3
		c0,9.9-2.1,38.3-28.9,53c-5.1,3-9,4.5-19.9,7.2l53,84.9H1096.5z M1097.1,64.9c-5.1-7.5-13.6-8.7-21.7-8.7h-63.3v39.2h57.2
		c14.5,0,22-1.8,26.8-7.5c3.9-4.8,4.2-9.6,4.2-12.1C1100.4,74,1100.1,69.1,1097.1,64.9z"/>
            <path className={`letter a ${on ? 'on' : 'off'}`} d="M846.8,220.7L835.3,186H754l-11.5,34.6h-83.8L750.7,8h86.8l92.2,212.7H846.8z M794.4,67L770,139h49.4L794.4,67z"/>
            <rect className={`letter blocker blocker-2 ${on ? 'on' : 'off'}`} x="423" y="45" width="150" height="60" ></rect>
            <path className={`letter p ${on ? 'on' : 'off'}`} d="M669.3,131.5c-20.5,13.9-50,14.2-73.2,14.2h-57.5v75h-75.9V8h145.8c7.2,0,14.8,0,22,0.6c25,1.5,46.4,7.5,58.4,31.6
		c3.3,6.9,7.5,17.8,7.5,35.6C696.5,86,695.3,114.3,669.3,131.5z M617.5,66.1c-5.1-9-14.5-9.9-23.5-9.9h-55.4v41.6h49.7
		c8.7,0,13,0,17.2-1.2c9.3-2.4,14.8-9.6,14.8-19.6C620.2,71.6,618.4,67.9,617.5,66.1z"/>
            <rect className={`letter blocker ${on ? 'on' : 'off'}`} x="-5" y="6" width="384" height="217" ></rect>
        </g>
    </svg>
  )
}
export default PalLogoAnim
