import React from 'react'
import {Link} from 'gatsby'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <div>
    <SEO title="404: Not found" />
    <h1>Page not found</h1>
    <p>
      You just hit a route that doesn&#39;t exist. Click{' '}
      <Link to="/">here</Link> to go to the home page.
    </p>
  </div>
)

export default NotFoundPage
