import React from 'react'
import Page from './Page'

import '../styles/pages/content-page.scss'

const ContentPage = class extends React.Component {
  render() {
    const {className, style} = this.props

    return (
      <Page
        headerBackgroundColor={'white'}
        logoColor={'black'}
        className={`content-page ${className ? className : ''}`}
        style={style}
      >
        <div className="content" ref={ref => (this.page = ref)}>
          <div className="content-container">
            {this.props.render && this.props.render({parent: this.page})}
            {!this.props.render && this.props.children}
          </div>
        </div>
      </Page>
    )
  }
}

export default ContentPage
