import React from 'react'
import '../styles/pages/page.scss'

const Page = class extends React.Component {
  render() {
    const {className, style, headerBackgroundColor, logoColor} = this.props
    return (
      <div
        className={`page ${className ? className : ''} ${
          headerBackgroundColor ? headerBackgroundColor : ''
        }`}
        style={style}
      >
        {this.props.children}
      </div>
    )
  }
}

export default Page
